import React from 'react';
import { Link } from 'gatsby';
import { HeaderSimple } from '../../components/headers';
import LandingImageOne from '../../components/images/LandingImageTwo';
import Layout from '../../layout';

import LandingImageThree from '../../components/images/LandingImageThree';
import SEO from '../../components/SEO';
import LogoReel from '../../components/images/logoreel';
import BgVideoTestimonial from '../../components/bgVideoTestimonial';
import ImageQuote from '../../components/images/ImageQuote';
import ItImage from '../../components/images/ItImage';
import ItImageTwo from '../../components/images/ItImageTwo';
import MediCaseOne from '../../components/images/MediCaseOne';
import MediLogoLight from '../../components/images/MediLogoLight';
import MediLogoDark from '../../components/images/MediLogoDark';
import MediCaseTwo from '../../components/images/medi-case-two';
import Quote from '../../components/Quotes';
import Clients from '../../components/clients';
import BookDiscoveryCall from '../../components/BookDiscoveryCall';

const It = () => (
  <Layout>
    <SEO />
    <div className="container-fluid p-0 mx-auto">
      <div className="row m-0 mx-auto min-vh-80">
        <div className="col-md-9 align-self-center text-center text-md-left mx-auto">
          <span className="p-3 bg-themeSecondary m-3" />
          <h2 className=" display-2 align-self-center font-weight-bold  text-neutralDark">
            We helped MediServices create an end-to-end digital and IT ecosystem
          </h2>
        </div>
      </div>

      <div className="row p-0 bg-white align-items-start m-0">
        <div className="col-md-9 mx-auto image-link-hover p-0">
          <div className="card case-study-height-auto case-study border-0 rounded-0 text-white">
            <MediCaseOne />
          </div>
        </div>
      </div>
      <div className="container-fluid bg-neutralLighterAlt">
        <div className="row h-100 min-vh-60  p-2 p-md-5">
          <p className="text-center col-md-12 text-muted text-uppercase font-weight-bold mt-3 inline-block px-3 py-5">
            <span className="p-3 bg-themeSecondary m-3" />
            How we helped
          </p>
          <div className="col-md-8 mx-auto align-self-center">
            <div className="row h-100  align-content-center">
              <div className="col-md-8 mx-auto align-self-center py-5 ">
                <div className="row h-100 align-content-center">
                  <div className="col-md-6 p-2 ">
                    <MediLogoLight />
                  </div>
                  <div className="col-md-6 p-2 bg-themePrimary ">
                    <MediLogoDark />
                  </div>
                </div>
              </div>
              <div className="col-md-12 h-100 text-center text-md-left mx-auto">
                <h2 className=" py-2  text-neutralSecondary">
                  We worked with MediServices to rebrand their business, for a modern, professional look. After a few
                  meetings, we began the process of the rebrand. Much of it included imagery, voice, tone and color
                  palette.
                </h2>
                <h2 className=" lead align-self-center font-weight-light  text-neutralTertiary">
                  Once we had rebranded, we started building the new website reflecting the new brand. A key element was
                  for the MediServices team to get inquiries from a form and get bookings this way. MediServices has
                  since grown its digital presence, boosted by our Digital Services.
                </h2>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row h-100 p-2 p-md-5">
          <p className="text-center col-md-12 text-muted text-uppercase font-weight-bold mt-3 inline-block px-3 py-5">
            <span className="p-3 bg-themeSecondary m-3" />
            Services we performed
          </p>
          <div className="col-md-6 align-self-center">
            <ul className="list-group-flush pl-2">
              <li className="list-group-item case-text text-themePrimary border-0">Rebranding</li>
              <li className="list-group-item case-text text-themePrimary border-0">Email Configurations</li>
              <li className="list-group-item case-text text-themePrimary border-0">Microsoft Office 365</li>
              <li className="list-group-item case-text text-themePrimary border-0">Social Media Design</li>
              <li className="list-group-item case-text text-themePrimary border-0">Domain Guardianship </li>
            </ul>
          </div>
          <div className="col-md-6 align-self-center">
            <ul className="list-group-flush pl-2">
              <li className="list-group-item case-text text-themePrimary border-0">Hosting</li>
              <li className="list-group-item case-text text-themePrimary border-0">Security, encryption and SSL</li>
              <li className="list-group-item case-text text-themePrimary border-0">Analytics and Tracking</li>
              <li className="list-group-item case-text text-themePrimary border-0">Technical SEO Technology</li>
              <li className="list-group-item case-text text-themePrimary border-0">Ongoing Marketing services</li>
              <li className="list-group-item case-text text-themePrimary border-0">Marketing Automation</li>
            </ul>
          </div>
        </div>
      </div>
      <Clients clientImage={<ItImage />} text=" We Make Digital and IT Simple" />
      <Quote
        video={<BgVideoTestimonial />}
        name="Andile Siziba"
        job="Director of Neurophysiology | MediServices Healthcare"
        quoteOne="Since approaching Onai Intelligence to build our website and re-brand our business, we have since seen an
                increase in our digital ROI."
        quoteTwo=" Onai has since extended our website to include other processes as well as their other Digital, IT and
                SEO services. I would highly recommend Onai to any small business"
      />
      <BookDiscoveryCall />
    </div>
  </Layout>
);

export default It;
