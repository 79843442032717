import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

// eslint-disable-next-line react/display-name
function MediLogoLight() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "Medi-Logo-light.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return <Img fluid={data.file.childImageSharp.fluid} className="" alt="" />;
}
export default MediLogoLight;
